/**
 * AAAA IDP Payment page
 * @flow
 */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ErrorApp from '../../components/error-app/ErrorApp';
import Loading from '../../components/loading/Loading';
import Progress from '../../components/progress/Progress';
import { Fn } from '../../functions/Functions';
import { API, gatewayForDomain, OPTIONS } from '../../data/Data';
import type { ApiResult, GatewayVars } from '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './Completed.css';

type Props = {
  bugsnagClient: Bugsnag,
  jest: boolean,
  location: Location,
};

type State = {
  appId: null | string,
  id: null | string,
  data: GatewayVars,
  gateway: {
    name: string,
    method: string,
    redirectUrl: string,
  },
  postageDescription: null | string,
  submission_token: null | string,
  retries: number,
  retryDelays: Array<number>
}

const LOADING_TEXT = 'Your transaction is processing. Do not use the browser back button, refresh, or leave this page until you see the confirmation notice';

export class Completed extends Component<Props, State> {
  static defaultProps = {
    jest: false,
    location: {
      search: '',
    },
  };
  
  constructor(props: Props) {
    super(props);
    const { jest, location } = props;
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const appId = params.get('appId');
    const id = params.get('id');
    const submission_token = params.get('submission_token');
    const postageDescription = params.get('postage');
    const responseDescription = params.get('responseDescription');
    const status = params.get('status');
    const apiSuccess = params.get('apiSuccess');
    const { host } = window.location;
    const gateway = gatewayForDomain(host, null, null, null, null);
    const data = Fn.extractGatewayVars(gateway.name, location, jest);
    
    this.state = {
      apiSuccess,
      appId,
      id,
      data,
      gateway,
      postageDescription,
      responseDescription,
      spinner: true,
      status,
      submission_token,
      retries: 0,
      retryDelay: [0, 1000, 2000, 3000, 4000, 8000]
    };

    this.timeout = null;
  }

  componentDidMount() {
    setTimeout(() => this.sendToApi(), 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  /**
  * Send transaction details to API
  */
  sendToApi = () => {
    let { data, appId, submission_token, retries } = this.state;

    if (!appId || !submission_token) {
      return;
    }

    data = {
      amount: data.paymentAmount,
      id: appId,
      hash_check: data.hashCheck,
      receipt_number: data.receiptNumber,
      response_code: data.responseCode,
      response_body: data.responseDescription,
      reference: data.paymentReference,
      status: data.success === null ? 'pending' : data.success ? 'success' : 'fail',
      transaction_timestamp: data.createdDateTime,
      submission_token,
      summary_code: data.summaryCode,
      token: data.cardToken
    };

    console.warn('data POST to API', data);
    this.setState({
      retries: retries + 1
    }, () => {
      console.warn('retries', this.state.retries);
    });

    const options = {
      ...OPTIONS,
      body: JSON.stringify(data),
    };
    const url = `${API}/${appId}/payment/transaction`;

    // send to API
    fetch(url, options)
      .then(
        response => {
          console.warn('response.status', response.status);
          
          if (response.status >= 400) {
            this.notOk(response);
          } else {
            return response.json();
          }
        }
      )
      .then(
        result => {
          if (result) {
            this.success(result);
          }
        }
      )
      .catch(
        err => this.error(err)
      )
  }

  /**
   * Handle >= 400 API response
   */
    notOk = (response: Response) => {
      response.json().then(
        result => this.fail(result, response.status)
      );
      return true;
    };
  

  /**
   * failed >=400 API response.json()
   */
  fail = (result: ApiResult, status: number) => {
    const { bugsnagClient, jest } = this.props;
    const { appId, gateway: { name } } = this.state;
    let { errors, message } = result;

    console.warn('Transaction failed application Id: ', appId);
    console.warn('API message: ', message);

    if (name === 'fatzebra') {
      if (errors) {
        for (let param in errors) {
          console.warn(errors[param]);
        }
      }
    }

    const err = new Error(`APi result failed ${status}; ${message ? message : ''}; ${errors ? JSON.stringify(errors) : ''}`)

    if (jest) {
      return null;
    }

    // Log to Bugsnag, then redirect
    bugsnagClient.notify(err, null, () => {
      this.redirect('true', 'fail', message ? message : '');
    });
    
  }

  /**
   * success posting to API, 200 response
   */
  success = (result: ApiResult) => {
    const {response_body, status} = result.data ? result.data : {
      response_body: '',
      status: '',
    };
    
    this.redirect('true', status ? status : '', response_body ? response_body : '');
  }

  /**
  * Error
  */
  error = async (err: Error, message: string = '') => {
    const { bugsnagClient, jest } = this.props;
    let { appId, retries } = this.state;

    if (jest) return null;

    console.warn(err);
    console.warn('window.location.search', window.location.search);
    console.warn('retries', retries);

    if (!message) {
      err.message = `${err.message}; Application id: ${appId ? appId : 'MISSING'}`;
    } else {
      console.warn(message);
    }

    if (retries >= 5 ) {
      // Log to Bugsnag, then redirect
      bugsnagClient.notify(err, null, () => {
        this.redirect('false', 'sent', '');
      });
    } else {
      bugsnagClient.notify(err, null, () => {
        this.retrySendToApi();
      });
      
    }
  }

  /**
   * Retry sending data to the API with back off
   */
  retrySendToApi = () => {
    this.timeout = setTimeout(() => this.sendToApi(), this.state.retryDelay[this.state.retries]);
  }

  /**
  * redirect with search params
  * @param {*} result 
  */
  redirect = (apiSuccess: string, status: string, responseDescription: string) => {
    const { jest } = this.props;
    const { appId, data, postageDescription, submission_token } = this.state;
    const { createdDateTime, paymentAmount } = data;
    const { origin } = window.location;
    const search = new URLSearchParams();
    search.set('createdDateTime', createdDateTime ? createdDateTime : '')
    search.set('id', appId ? appId : '');
    search.set('submission_token', submission_token ? submission_token : '');
    search.set('apiSuccess', apiSuccess);
    search.set('status', status);
    search.set('paymentAmount', paymentAmount ? paymentAmount : '');
    search.set('postageDescription', postageDescription ? postageDescription : '');
    search.set('responseDescription', responseDescription);

    if (jest) return null;
    // Replace href so history of the completed page is removed
    window.location.href = `${origin}/confirmation?${search.toString()}`;
  }

  render() {
    const { bugsnagClient } = this.props;
    const { appId, submission_token } = this.state;

    if (!appId || !submission_token) {
      return <ErrorApp bugsnagClient={bugsnagClient} />;
    }

    return (
      <Fragment>
        <Progress />
        <section className="form-container">
          <div className="Completed">
            <Loading text={LOADING_TEXT} />
          </div>
        </section>
      </Fragment>
      );
  }
}
 
 export default withRouter(Completed);