/**
 * AAA IDP Footer component
 * @flow
 */
import * as React from 'react';
import ErrorValidation from '../../../components/error-validation/ErrorValidation';
import ErrorApi from '../../../components/error-api/ErrorApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect } from 'react-router-dom';
import { deleteAll, validate, acceptDigitalLicence, acceptTerms, licenceDeliveryAddressCountryReset} from '../../../actions/';

import { connect } from 'react-redux';
import { countErrors, Fn } from '../../../functions/Functions';
import { API, OPTIONS, POSTAGE } from '../../../data/Data';
import './Footer.css';
import type { Application, AppState, ApiResult, Transaction } from '../../../types/Types';
import type { Bugsnag } from '@bugsnag/js';

type Props = {
  ...AppState,
  application: Application,
  bugsnagClient: Bugsnag,
  cookies: boolean,
  digitalLicenceTerms: { value: boolean },
  terms: { value: boolean },
  deleteAll: () => *,
  jest: boolean,
  location: {
    search: string
  },
  transaction: Transaction,
  validate: () => *,
  setTerms: () => *,
  internationalShippingError: () => *
};

type State = {
  apiError: boolean,
  apiErrorMessage: string,
  confirmCancel: boolean,
  focus: string,
  next: boolean,
  spinner: boolean,
  submitting: boolean
};

export class Footer extends React.Component<Props, State> {
  static defaultProps = {
    application: {
      referrer: 'http://localhost:3000/'
    },
    bugsnagClient: {
      notify: () => null
    },
    club: {
      autoClub: {
        error: false
      }
    },
    contacts: {
      contactNumber: {
        error: false
      }
    },
    dateOfBirth: {
      error: false
    },
    dateOfTravel: {
      error: false
    },
    email: {
      error: false
    },
    jest: false,
    licenceClass: {
      error: false
    },
    licenceExpiry: {
      error: false
    },
    licenceNumber: {
      error: false
    },
    names: {
      fullName: {
        error: false
      }
    },
    placeOfBirth: {
      error: false
    },
    postage: {
      ...POSTAGE[0]
    },
    stateOfIssue: {
      error: false
    },
    wearGlasses: {
      error: false
    },
    terms: {
      error: false
    },
    digitalLicenceTerms: {
      error: false
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      apiError: false,
      apiErrorMessage: '',
      confirmCancel: false,
      focus: '',
      next: false,
      spinner: false,
      submitting: false
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { focus } = this.state;

    if (focus && (key === ' ' || key === 'Enter')) {
      switch (focus) {
        case 'cancel':
          this.cancel();
          break;

        case 'confirm':
          this.cancel();
          break;

        case 'continue':
          this.continue();
          break;

        case 'next':
          this.next();
          break;

        default:
        // no op
      }
    }
  };

  /**
   * onFocus
   */
  onFocus = (focus: string) => {
    this.setState({
      focus
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: ''
    });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevState.submitting && this.state.submitting) {
      this.allValid();
    }
  }

  /**
   * are all fields valid?
   */

  allValid = () => {
    const errors = countErrors(this.props);
    let sanityCheckErrors = errors;
    if (this.props.addresses?.licenceDelivery
       && this.props.addresses?.licenceDelivery === 'International'
       && this.props.addresses?.licenceDeliveryAddressCountry?.value) {
      let countryValue = this.props.addresses?.licenceDeliveryAddressCountry?.value;
      // We cant have this it says "select" but its allready prefilled.
      if (countryValue === 'AU') {
        this.props.internationalShippingError();
        sanityCheckErrors = sanityCheckErrors + 1;
      }
    }

    /**
     * Somehow validation is not working for checkboxes.
     */
    if (this.props.digitalLicenceTerms && this.props.terms) {
      if (this.props.digitalLicenceTerms.value === false) {
        sanityCheckErrors = sanityCheckErrors + 1;
        this.props.setTerms('digitalLicenceTerms');
      }
      if (this.props.terms.value === false) {
        sanityCheckErrors = sanityCheckErrors + 1;
        this.props.setTerms('terms');
      }
    }


    if (sanityCheckErrors === 0) {
      this.submit();
    } else {
      this.setState({
        next: false,
        submitting: false,
        spinner: false
      });
    }
  };

  /**
   * submit
   */
  submit = () => {
    const { application } = this.props;

    const data = Fn.formatData(this.props);

    const options = {
      ...OPTIONS,
      method: 'PUT',
      body: JSON.stringify(data)
    };
    //console.log(data);return;
    const url = `${API}/${application.id}`;

    this.setState({
      spinner: true
    });

    fetch(url, options)
      .then(response => {
        if (response.status >= 400) {
          this.setState({
            apiError: true,
            spinner: false
          });
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(result => this.success(result))
      .catch(err => this.error(err));
  };

  /**
   * success posting to API
   */
  success = (result: ApiResult) => {
    const { jest } = this.props;

    if (result?.data?.order_status &&  result?.data?.order_status !== "Pending") {
      this.cancelAndRedirect();
    }
    

    // handle API validation errors
    if (result.errors || result.message === 'Server Error') {
      this.validationError(result);
    } else {
      if (!jest) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }

      setTimeout(() => {
        this.setState({
          next: true,
          spinner: false
        });
      }, 1000);
    }
  };

  /**
   * Error
   */
  error = (err: Error) => {
    const { application, bugsnagClient, jest } = this.props;

    if (jest) return null;
    console.warn(err);

    err.message = `${err.message}; Application id: ${application.id}`;

    bugsnagClient.notify(err);
  };

  /**
   * API validation error(s)
   */
  validationError = (result: ApiResult) => {
    this.setState({
      apiError: true,
      apiErrorMessage: result.message,
      spinner: false
    });

    const msg = `API error${result.message ? ` ${result.message}` : ''}${
      result.errors ? ` Error(s): ${JSON.stringify(result.errors)}` : ''
    }`;
    const error = new Error(msg);
    this.error(error);
  };

  /**
   * Go to summary?
   */
  next = () => {
    this.props.validate();
    this.setState({
      submitting: true
    });
  };

  /**
   * cancel application
   */
  cancel = () => {
    const { application, deleteAll } = this.props;
    const { confirmCancel } = this.state;

    if (!confirmCancel) {
      this.setState({
        confirmCancel: true
      });
      return;
    }

    deleteAll();
    window.location = application.referrer;
  };

  /**
   * Cancel application and redirect
   */
  cancelAndRedirect = () => {
    const { application } = this.props;
    deleteAll();
    window.location = application.referrer;
  };



  /**
   * Continue application / cancel cancelling application
   */
  continue = () => {
    this.setState({
      confirmCancel: false
    });
  };

  render() {
    const { apiError, confirmCancel, next, spinner } = this.state;
    const errors = countErrors(this.props);
    const disabled = errors > 0 || apiError ? ' disabled' : '';

    if (next) {
      return <Redirect to="/summary" />;
    }

    return (
      <React.Fragment>
        <ErrorValidation errors={errors} />
        <ErrorApi apiError={apiError} />
        <section className="Footer">
          {confirmCancel ? (
            <React.Fragment>
              <div className="confirm-cancel-text">
                Are you sure you want to <strong>CANCEL</strong> your application?
              </div>
              <div
                className="button reverse cancel confirm"
                onClick={this.cancel}
                tabIndex="0"
                role="button"
                onFocus={() => this.onFocus('confirm')}
                onBlur={this.onBlur}
              >
                <FontAwesomeIcon icon="xmark" />
                Yes, Cancel
              </div>
              <div
                className="button reverse cancel continue"
                onClick={this.continue}
                tabIndex="0"
                role="button"
                onFocus={() => this.onFocus('continue')}
                onBlur={this.onBlur}
              >
                <FontAwesomeIcon icon="rotate-left" />
                No, don't cancel
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div
                className={'button reverse cancel'}
                onClick={this.cancel}
                tabIndex="0"
                role="button"
                onFocus={() => this.onFocus('cancel')}
                onBlur={this.onBlur}
              >
                <FontAwesomeIcon icon="xmark" />
                Cancel application
              </div>
              <div
                className={`button next${disabled}`}
                onClick={() => this.next()}
                tabIndex="0"
                role="button"
                onFocus={() => this.onFocus('next')}
                onBlur={this.onBlur}
              >
                {spinner ? <FontAwesomeIcon icon="spinner" spin={true} /> : <FontAwesomeIcon icon="chevron-right" />}
                Next: Summary
              </div>
            </React.Fragment>
          )}
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  addresses,
  application,
  club,
  contacts,
  cookies,
  dateOfBirth,
  dateOfTravel,
  digitalLicenceTerms,
  email,
  images,
  names,
  licenceClass,
  licenceExpiry,
  licenceNumber,
  placeOfBirth,
  postage,
  stateOfIssue,
  transaction,
  wearGlasses,
  terms
}) => {
  return {
    addresses,
    application,
    club,
    contacts,
    cookies,
    dateOfBirth,
    dateOfTravel,
    digitalLicenceTerms,
    email,
    images,
    names,
    licenceClass,
    licenceExpiry,
    licenceNumber,
    placeOfBirth,
    postage,
    stateOfIssue,
    transaction,
    wearGlasses,
    terms
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAll: () => {
      dispatch(deleteAll());
    },
    validate: () => {
      dispatch(validate());
    },
    setTerms: (fieldName) => {
      if (fieldName === 'digitalLicenceTerms') {
        dispatch(acceptDigitalLicence(false));
      }
      if (fieldName === 'terms') {
        dispatch(acceptTerms(false))
      }
    },
    internationalShippingError: () => {
      dispatch(licenceDeliveryAddressCountryReset());
    },
  };
};

const VisibleFooter = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default VisibleFooter;
