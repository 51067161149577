/**
 * AAAA IDP Completed Payment page
 * @flow
 */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorApp from '../../components/error-app/ErrorApp';
import ConfirmationTable from './confirmation-table/ConfirmationTable';
import NextSteps from './next-steps/NextSteps';
import Footer from './footer/Footer';
import { Link } from 'react-router-dom';
import { clubDomain } from '../../data/Data';
import { SITE_CONTENT } from '../../reducers/content/Site.content';
import type { Content } from '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './Confirmation.css';

type Props = {
  bugsnagClient: Bugsnag,
  location: Location,
  content: Content,
  setContent: (content: Content) => *
};

type State = {
  apiSuccess: null | string,
  createdDateTime: null | string,
  id: string | null,
  paymentAmount: null | string,
  postageDescription: null | string,
  responseDescription: null | string,
  spinner: boolean,
  status: null | string,
  submission_token: string | null
};

export class Confirmation extends Component<Props, State> {
  static defaultProps = {
    content: { ...SITE_CONTENT },
    location: {
      search: ''
    }
  };

  constructor(props: Props) {
    super(props);
    const { search } = props.location;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const createdDateTime = params.get('createdDateTime');
    const submission_token = params.get('submission_token');
    const paymentAmount = params.get('paymentAmount');
    const postageDescription = params.get('postageDescription');
    const responseDescription = params.get('responseDescription');
    const status = params.get('status');
    const apiSuccess = params.get('apiSuccess');

    this.state = {
      apiSuccess,
      createdDateTime,
      id,
      paymentAmount,
      postageDescription,
      responseDescription,
      spinner: true,
      status,
      submission_token
    };
  }

  render() {
    const { bugsnagClient, content } = this.props;
    const { hostname } = window.location;
    const {
      apiSuccess,
      createdDateTime,
      id,
      paymentAmount,
      postageDescription,
      responseDescription,
      status,
      submission_token
    } = this.state;
    const { nextSteps } = content;
    const club = clubDomain(hostname);

    // http://racq.idp.local:3000/confirmation?createdDateTime=2021-07-22+12%3A17%3A54&id=740&submission_token=6LN2emhmhB&apiSuccess=true&status=fail&paymentAmount=51.00&postageDescription=Express+Post+within+Australia&responseDescription=Declined
    switch (true) {
      case !id || !submission_token:
        return <ErrorApp bugsnagClient={bugsnagClient} />;

      case status === 'sent' && apiSuccess === 'false':
        return (
          <Fragment>
            <section className="content-container Confirmation">
              <h2>Your payment was successful</h2>
              <div>
                <p className="error">
                  <FontAwesomeIcon icon="triangle-exclamation" />
                  There was an error with your payment.
                </p>
                <p className="error">Please contact {club} to confirm your order.</p>
                <ConfirmationTable
                  {...this.props}
                  createdDateTime={createdDateTime}
                  id={id}
                  paymentAmount={paymentAmount}
                  postageDescription={postageDescription}
                />
              </div>

              <Footer apiSuccess={apiSuccess} status={status} />
            </section>
          </Fragment>
        );

      case status === 'fail' && apiSuccess === 'true':
        return (
          <Fragment>
            <section className="content-container Confirmation">
              <h2>Your payment was unsuccessful</h2>
              <div>
                {responseDescription ? (
                  <p className="error">
                    <FontAwesomeIcon icon="triangle-exclamation" />
                    {responseDescription}
                  </p>
                ) : null}
                <p>
                  Please confirm the details and <Link to="/payment">try payment again</Link>
                </p>
              </div>

              <Footer apiSuccess={apiSuccess} status={status} />
            </section>
          </Fragment>
        );

      default:
        return (
          <Fragment>
            <section className="content-container Confirmation">
              <h2>Thanks for your application.</h2>
              <p>
                Your order number is {id}. Please use this reference in any future correspondence. You will receive a
                confirmation email shortly confirming your application has been received.
              </p>

              <ConfirmationTable
                {...this.props}
                createdDateTime={createdDateTime}
                id={id}
                paymentAmount={paymentAmount}
                postageDescription={postageDescription}
              />
              <h2>{nextSteps.title}</h2>
              <NextSteps {...nextSteps} />
              <Footer apiSuccess={apiSuccess} status={status} />
            </section>
          </Fragment>
        );
    }
  }
}

export default withRouter(Confirmation);
